import axios from "axios";

import authorizationRequestInterceptor from "@/vue/apps/shared/plugins/axios/interceptors/request/authorization.js";
import {
  onFulfilled,
  onRejected,
} from "@/vue/apps/shared/plugins/axios/interceptors/response/validation";

export function initAxiosInterceptors(store) {
  // Register the authorization request interceptor;
  axios.interceptors.request.use(authorizationRequestInterceptor(store));

  // Register the unauthorized response interceptor
  axios.interceptors.response.use(onFulfilled, onRejected);
}
