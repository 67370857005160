export default (store) => (request) => {
  // If not an API v3 route, exit early by returning the request.
  if (!new URL(request.url).pathname.startsWith("/v3/")) {
    return request;
  }

  // If there is no access token in the authentication module, exit early by returning the request.
  if (!store.getters["auth/hasAccessToken"]) {
    return request;
  }

  // Otherwise, retrieve the token and use it to add an Authorization header to the request.
  const token = store.getters["auth/accessToken"];
  request.headers.Authorization = `Bearer ${token}`;

  return request;
};
